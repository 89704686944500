import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Container } from 'reactstrap';
import SEO from '../components/SEO';
import LinedTitle from '../components/LinedTitle';
import { COLORS } from '../constants/style';
import EnrolmentStep from '../components/EnrolmentStep';

const Page = styled.div`
  padding-top: 120px;
`;

const ClassYears = styled.section`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  .col-md-4 {
    padding: 0.2em 1em;
  }

  .col-md-4:not(:last-child) {
    border-right: solid 1px ${COLORS.textOffWhite};
  }
  @media (max-width: 767px) {
    .col-md-4:not(:last-child) {
      border-right: none;
      border-bottom: solid 1px ${COLORS.textOffWhite};
      margin-bottom: 1em;
    }
  }
  h3 {
    font-weight: 900;
    margin-bottom: 0.8em;
  }
  p {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
  img {
    height: 50px;
    margin-right: 12px;
  }
`;

const ClassTypeSection = styled.section`
  padding-top: 60px;
  padding-bottom: 60px;
  background: ${COLORS.lightGray};
  text-align: center;

  .class-types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .each-type {
      img {
        height: 110px;
        margin-bottom: 0.8em;
      }
      .heading {
        color: ${COLORS.darkGold};
        font-size: 1.5em;
        font-weight: 900;
        margin-bottom: 0.5em;
      }

      ul {
        text-align: left;
        display: inline-block;
        margin-left: 0;
        li {
          font-size: 1.2em;
        }
      }
    }
  }
`;

const EnrollSection = styled.section``;

const FootnoteSection = styled.section`
  background: ${COLORS.lightGray};
  padding: 50px 0;

  ol {
    margin-top: 2em;
    li {
      margin-bottom: 1em;
      font-size: 1.1em;
    }
  }

  .footnote {
    margin-top: 3em;
    text-align: right;
    font-style: italic;
  }
`;

export default function Blog({ data }) {
  const { seoMetaTags } = data.page;
  return (
    <>
      <SEO meta={seoMetaTags} />
      <Page>
        <Container>
          <h2 className="text-center">
            The Edcellent classes are designed for:
          </h2>
          <ClassYears className="row no-gutters">
            <div className="col-md-4">
              <h3>
                <img src={'/img/years/y5.svg'} alt="y5" />
                Year 3 - 6
              </h3>
              <p className="font-weight-bold">Junior School English</p>
              <ul className="text-left d-table mx-auto">
                <li>English</li>
                <li>EAL</li>
              </ul>
            </div>
            <div className="col-md-4">
              <h3>
                <img src={'/img/years/y7.svg'} alt="y5" />
                Year 7 - 9
              </h3>
              <p className="font-weight-bold">Middle School</p>
              <ul className="text-left d-table mx-auto">
                <li>English</li>
                <li>EAL</li>
                <li>Literature</li>
                <li>Pre VCE</li>
              </ul>
            </div>
            <div className="col-md-4">
              <h3>
                <img src={'/img/years/y10.svg'} alt="y5" />
                Year 10 - 12
              </h3>
              <p className="font-weight-bold">Senior School</p>
              <ul className="text-left d-table mx-auto">
                <li>IB English</li>
                <li>VCE English</li>
                <li>VCE EAL</li>
                <li>English Language</li>
                <li>English Literature</li>
              </ul>
            </div>
          </ClassYears>
        </Container>
        <ClassTypeSection>
          <Container>
            <LinedTitle className="mb-5" wing>
              Our Class Types
            </LinedTitle>
            <div className="class-types">
              <div className="each-type">
                <img src="/img/class-types/offline.svg" alt="face-to-face" />
                <p className="heading">Face-to-face Learning</p>
                <ul>
                  <li>One-to-one classes</li>
                  <li>Small group classes</li>
                  <li>Exam Preparation classes</li>
                </ul>
              </div>
              <div className="each-type">
                <img src="/img/class-types/online.svg" alt="online class" />
                <p className="heading">Online Classes</p>
                <ul>
                  <li>One-to-one classes</li>
                  <li>Small group classes</li>
                </ul>
              </div>
            </div>
          </Container>
        </ClassTypeSection>
        <EnrollSection id="enrol">
          <Container className="py-5">
            <h1 className="text-center mb-4">Our Enrolment Process</h1>
            <EnrolmentStep
              step="1"
              // icon={'/img/enrol-steps/contact.svg'}
              title={'Contact us via Phone / Email / WeChat admin.'}
              withContactDetails
            />
            <div className="row">
              <div className="col-md-6 d-flex">
                <EnrolmentStep
                  className="flex-fill"
                  step="2"
                  icon={'/img/enrol-steps/schedule.svg'}
                  title={
                    'We will organise a time for you to meet with our Principal.'
                  }
                />
              </div>
              <div className="col-md-6 d-flex">
                <EnrolmentStep
                  className="flex-fill"
                  step="3"
                  icon={'/img/enrol-steps/planning.svg'}
                  title={
                    'We will provide you with the strategies to learn and improve the status quo.'
                  }
                />
              </div>
            </div>
            <EnrolmentStep
              step="4"
              icon={'/img/enrol-steps/class.svg'}
              title={
                'We will allocate you to the class that best suits your learning needs!'
              }
            />
          </Container>
        </EnrollSection>
        <FootnoteSection>
          <Container>
            <LinedTitle wing>We monitor our student progress</LinedTitle>
            <ol>
              <li>
                Parents will receive the student’s progress report at the end of
                Term 1 and Term 3.{' '}
              </li>
              <li>
                We encourage our parents to contact our support team to enquire
                or discuss your child’s learning between 1pm and 6pm from
                Wednesday to Sunday.{' '}
              </li>
            </ol>
            <div className="footnote">
              You will undertake a 20-minute meeting with the Head of VCE or IB
              to discuss strategies to work towards your goal.{' '}
            </div>
          </Container>
        </FootnoteSection>
      </Page>
    </>
  );
}

export const query = graphql`
  {
    page: datoCmsClassesPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
