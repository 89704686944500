import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../constants/style';
import NumberBadge from './NumberBadge';

const Step = styled.div`
  border: solid 1px ${COLORS.brightGold};
  border-radius: 3px;
  padding: 2em 1.5em;
  width: 100%;
  margin-bottom: 1.5em;
  text-align: center;

  .title {
    margin-top: 0.8em;
    font-weight: 900;
  }

  img.icon {
    height: 100px;
    margin: 1em 0;
  }

  .contact-details {
    margin-top: 1em;
    a {
      color: ${COLORS.darkGold};
    }
    p {
      font-size: 1.2em;
      margin: 0 0 0.4em 0;
    }
    img.qr {
      height: 200px;
    }
  }
`;

const EnrolmentStep = ({
  step,
  icon,
  title,
  withContactDetails = false,
  ...props
}) => {
  return (
    <Step {...props}>
      <NumberBadge>{step}</NumberBadge>
      <h3 className="title flex-fill">{title}</h3>
      {icon && <img className="icon" src={icon} alt="step icon" />}
      {withContactDetails && (
        <div className="contact-details">
          <p>
            <strong>Phone:</strong>{' '}
            <a href="tel:+61 417 313 116">+61 417 313 116</a>
          </p>
          <p>
            <strong>Email:</strong>{' '}
            <a href="mailto:support@edcellent.com">support@edcellent.com</a>
          </p>
          <img className="qr" src="/img/qr/cbd.png" alt="WeChat QR" />
          <img className="qr" src="/img/qr/boxhill.jpg" alt="WeChat QR" />
        </div>
      )}
    </Step>
  );
};

export default EnrolmentStep;
