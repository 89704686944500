import React from 'react';
import styled from 'styled-components';
import { COLORS, FONTS } from '../constants/style';

const Badge = styled.div`
  display: block;
  margin: 0 auto;
  background: ${COLORS.darkGold};
  border-radius: 50%;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 72px;

  font-size: 50px;
  color: ${COLORS.textWhite};
  font-family: ${FONTS.secondary};
  font-weight: 900;
`;

const NumberBadge = ({ children, ...props }) => {
  return <Badge {...props}>{children}</Badge>;
};

export default NumberBadge;
